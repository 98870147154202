import React from 'react';
import type {DockerImageInputValue} from '../../../components/DockerImageInput/types';
import {DockerImageInputContainer} from '../../DockerImageInputContainer/DockerImageInputContainer';

type DockerImageInputControlProps = {
    value: DockerImageInputValue;
    onChange: (value: DockerImageInputValue) => void;
    error?: string;
};

export function DockerImageInputControl(props: DockerImageInputControlProps) {
    return <DockerImageInputContainer {...props} />;
}

DockerImageInputControl.getDefaultValue = (): DockerImageInputValue => {
    return {
        image: '',
        option: 'registry',
    };
};

DockerImageInputControl.hasErrorRenderer = true;

DockerImageInputControl.isEmpty = (value: DockerImageInputControlProps['value']) => {
    return !value.image;
};
