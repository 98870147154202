import React from 'react';
import type {DockerImageInputElementsCommonProps} from '../types';
import {Flex, Text, TextInput} from '@gravity-ui/uikit';

type DockerImageRegistryInputProps = DockerImageInputElementsCommonProps;

export const DockerImageRegistryInput: React.FC<DockerImageRegistryInputProps> = (
    props: DockerImageRegistryInputProps,
) => {
    const {onChange, value, error} = props;

    return (
        <Flex direction="column" gap="1">
            <TextInput value={value} onUpdate={onChange} autoComplete={false} />
            <Text variant="body-2" color="danger">
                {error}
            </Text>
        </Flex>
    );
};
