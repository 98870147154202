import React from 'react';
import type {
    DockerImageInputOption,
    DockerImageInputValue,
} from '../../components/DockerImageInput/types';
import {
    DockerImageInput,
    type DockerImageInputProps,
} from '../../components/DockerImageInput/DockerImageInput';
import {useSelector} from 'react-redux';
import {getBlueprintWizardUrl} from '../../store/selectors/tracto-cluster-ui-config';
import type {RadioButtonOption} from '@gravity-ui/uikit';

type DockerImageInputContainerProps = {
    value: DockerImageInputValue;
    onChange: (v: DockerImageInputValue) => void;
    error?: string;
};

const useDockerImageInput = (props: DockerImageInputContainerProps): DockerImageInputProps => {
    const blueprint = useSelector(getBlueprintWizardUrl);

    const extendOptions = React.useCallback(
        (options: RadioButtonOption<DockerImageInputOption>[]) => {
            return options.map((option) => {
                if (option.value === 'blueprint') {
                    return {
                        ...option,
                        disabled: !blueprint,
                    };
                }

                return option;
            });
        },
        [blueprint],
    );

    return {
        ...props,
        extendOptions,
    };
};

export const DockerImageInputContainer: React.FC<DockerImageInputContainerProps> = (
    props: DockerImageInputContainerProps,
) => {
    return <DockerImageInput {...useDockerImageInput(props)} />;
};
