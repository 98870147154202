import React from 'react';
import PathEditor from '@ytsaurus-ui-platform/src/ui/containers/PathEditor/PathEditor';
import {Button, Flex, Icon, Loader, TextInput, type TextInputProps} from '@gravity-ui/uikit';
import {ArrowsRotateLeft} from '@gravity-ui/icons';
import block from 'bem-cn-lite';
import {BlueprintInputError} from './elements/BlueprintInputError';
import type {FetchImageErrorCodes} from '../../yt-api/registry/image';

import './BlueprintInput.scss';

export type BlueprintInputProps = {
    onPathChange: (path: string) => void;
    onRefresh: () => void;
    image: string;
    defaultPath?: string;
    error?: string;
    loading?: boolean;
    errorActions: Partial<Record<FetchImageErrorCodes | string, () => Promise<void>>>;
};

const b = block('blueprint-input');

export const BlueprintInput: React.FC<BlueprintInputProps> = (props: BlueprintInputProps) => {
    const {onPathChange, error, image, defaultPath, loading, errorActions, onRefresh} = props;

    const controlProps: TextInputProps['controlProps'] = React.useMemo(() => {
        return {title: image};
    }, [image]);

    return (
        <Flex gap="2" direction="column" className={b('')}>
            <Flex direction="column" gap="2">
                <div className={b('path-input-wrapper')}>
                    <PathEditor
                        placeholder="Enter the path to blueprint..."
                        defaultPath={defaultPath}
                        onChange={onPathChange}
                    />
                    <Button onClick={onRefresh} size="s" view="flat" className={b('refresh')}>
                        <Icon data={ArrowsRotateLeft} />
                    </Button>
                </div>
                {error && <BlueprintInputError error={error} actions={errorActions} />}
            </Flex>
            <Flex className={b('image-input-wrapper')}>
                <TextInput
                    label="Image"
                    controlProps={controlProps}
                    error={Boolean(error)}
                    disabled={true}
                    value={image}
                />
                {loading && <Loader className={b('loader')} />}
            </Flex>
        </Flex>
    );
};
