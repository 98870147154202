import axios, {type CancelToken} from 'axios';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import {getBlueprintNodeContent, getIsBlueprintCypressNode} from '../v4/blueprint';

import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
const cancelHelper = new CancelHelper();

export const enum FetchImageErrorCodes {
    BlueprintNotFound = 'blueprint_not_found',
    ImageNotFound = 'image_not_found',
    FailedToLoadImage = 'failed_to_load_image',
}

export const fetchImageWithTags = async ({
    repository,
    cancelToken,
}: {
    repository: string;
    cancelToken?: CancelToken;
}) => {
    const res = await axios.get<{
        name: string;
        tags: Array<{name: string; created: string}>;
    }>(`/${YT.cluster}/tracto-registry/api/image/${repository}/tags`, {cancelToken});

    return res.data;
};

export const fetchBlueprintImage = async (path: string) => {
    try {
        const isBlueprint = await getIsBlueprintCypressNode(path).catch(() => false);

        if (!isBlueprint) {
            throw new Error(FetchImageErrorCodes.BlueprintNotFound);
        }

        const response = await getBlueprintNodeContent(path);
        const {docker_params} = response.value;
        const imagePath = docker_params.image.name;

        if (!imagePath) {
            throw new Error(FetchImageErrorCodes.ImageNotFound);
        }

        const image = await fetchImageWithTags({
            repository: imagePath,
            cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
        });
        return `${docker_params.image.registry}/${image.name}:${image.tags[0].name}`;
    } catch (error) {
        if (error instanceof Error) {
            throw error;
        }
        throw new Error(FetchImageErrorCodes.FailedToLoadImage);
    }
};
