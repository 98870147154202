import React from 'react';
import {Button, Flex, Text} from '@gravity-ui/uikit';
import {FetchImageErrorCodes} from '../../../yt-api/registry/image';

type BlueprintInputErrorProps = {
    error: FetchImageErrorCodes | string;
    actions: Partial<Record<FetchImageErrorCodes | string, () => Promise<void>>>;
};

const typeToErrorText: Record<FetchImageErrorCodes | string, string> = {
    [FetchImageErrorCodes.BlueprintNotFound]: "Blueprint doesn't exist by provided path",
    [FetchImageErrorCodes.ImageNotFound]: "Image doesn't exist by provided blueprint",
    [FetchImageErrorCodes.FailedToLoadImage]: 'Failed to load image by provided path',
};

const typeToActionText: Partial<Record<FetchImageErrorCodes | string, string>> = {
    [FetchImageErrorCodes.BlueprintNotFound]: 'Create blueprint',
};

export const BlueprintInputError: React.FC<BlueprintInputErrorProps> = (
    props: BlueprintInputErrorProps,
) => {
    const {error, actions} = props;

    const [loading, setLoading] = React.useState(false);

    const errorText = typeToErrorText[error] ?? error;
    const actionText = typeToActionText[error];

    const action = actions[error];

    const handleClick = React.useCallback(() => {
        setLoading(true);
        action?.().finally(() => setLoading(false));
    }, [action]);

    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Text color="danger" variant="body-2">
                {errorText}
            </Text>
            {action && actionText && (
                <Button view="action" onClick={handleClick} size="s" loading={loading}>
                    {actionText}
                </Button>
            )}
        </Flex>
    );
};
