import React from 'react';
import {Flex, RadioButton, type RadioButtonOption} from '@gravity-ui/uikit';
import type {DockerImageInputOption, DockerImageInputValue} from './types';
import {DOCKER_IMAGE_INPUT_OPTIONS} from './constants';
import {DockerImageRegistryInput} from './elements/DockerImageRegistryInput';
import {DockerImageBlueprintInput} from './elements/DockerImageBlueprintInput';

export type DockerImageInputProps = {
    value: DockerImageInputValue;
    onChange: (v: DockerImageInputValue) => void;
    error?: string;
    extendOptions?: (
        options: RadioButtonOption<DockerImageInputOption>[],
    ) => RadioButtonOption<DockerImageInputOption>[];
};

export const DockerImageInput: React.FC<DockerImageInputProps> = (props: DockerImageInputProps) => {
    const {onChange, value, extendOptions, error} = props;

    const [images, setImages] = React.useState<
        Record<DockerImageInputOption, {image: string; path?: string}>
    >(() => ({
        registry: {image: ''},
        marketplace: {image: ''},
        blueprint: {image: ''},
        [value.option]: {image: value.image, path: value.path},
    }));

    const handleOptionChange = React.useCallback(
        (v: DockerImageInputOption) => {
            onChange({option: v, image: images[v].image, path: images[v].path});
        },
        [onChange, images],
    );

    const handleImageChange = React.useCallback(
        (v: string, options?: {path?: string}) => {
            onChange({option: value.option, image: v, path: options?.path});
            setImages((prev) => ({...prev, [value.option]: {image: v, path: options?.path}}));
        },
        [value.option, onChange],
    );

    const options = React.useMemo(() => {
        return extendOptions
            ? extendOptions(DOCKER_IMAGE_INPUT_OPTIONS)
            : DOCKER_IMAGE_INPUT_OPTIONS;
    }, [extendOptions]);

    return (
        <Flex direction="column" gap="2">
            <RadioButton<DockerImageInputOption>
                width="max"
                value={value.option}
                onUpdate={handleOptionChange}
                options={options}
            />
            {value.option === 'registry' && (
                <DockerImageRegistryInput
                    error={error}
                    value={images.registry.image}
                    onChange={handleImageChange}
                />
            )}
            {value.option === 'blueprint' && (
                <DockerImageBlueprintInput
                    error={error}
                    path={images.blueprint.path}
                    value={images.blueprint.image}
                    onChange={handleImageChange}
                />
            )}
        </Flex>
    );
};
