import React from 'react';
import type {DockerImageInputElementsCommonProps} from '../types';
import {BlueprintInputContainer} from '../../../containers/BlueprintInputContainer/BlueprintInputContainer';

type DockerImageBlueprintInputProps = DockerImageInputElementsCommonProps & {path?: string};

export const DockerImageBlueprintInput: React.FC<DockerImageBlueprintInputProps> = (
    props: DockerImageBlueprintInputProps,
) => {
    const {onChange, value, path, error} = props;

    const handleChange = React.useCallback(
        (args: {path?: string; image: string}) => {
            onChange(args.image, {path: args.path});
        },
        [onChange],
    );

    return (
        <BlueprintInputContainer
            error={error}
            defaultPath={path}
            value={value}
            onChange={handleChange}
        />
    );
};
