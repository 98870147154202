import React from 'react';
import {
    BlueprintInput,
    type BlueprintInputProps,
} from '../../components/BlueprintInput/BlueprintInput';
import {FetchImageErrorCodes, fetchBlueprintImage} from '../../yt-api/registry/image';
import debounce from 'lodash/debounce';
import {createBlueprint} from '../../yt-api/v4/blueprint';
import {getBlueprintIdFromPath} from '../../utils/blueprint';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import {genNavigationUrl} from '@ytsaurus-ui-platform/src/ui/utils/navigation/navigation';
import type {AxiosError} from 'axios';

type BlueprintInputContainerProps = {
    value: string;
    defaultPath?: string;
    error?: string;
    onChange: (args: {path?: string; image: string}) => void;
};

const useBlueprintInput = (props: BlueprintInputContainerProps): BlueprintInputProps => {
    const {onChange, value, defaultPath} = props;

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const onChangeRef = React.useRef(onChange);
    const pathRef = React.useRef(defaultPath);

    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    const handlePathChange = React.useMemo(
        () =>
            debounce(async (path: string) => {
                try {
                    const image = await fetchBlueprintImage(path);
                    setError('');
                    onChangeRef.current({path, image});
                } catch (e: any) {
                    setError(e.message);
                    onChangeRef.current({path, image: ''});
                } finally {
                    setLoading(false);
                }
            }, 1500),
        [],
    );

    React.useEffect(() => {
        return () => handlePathChange.cancel();
    }, [handlePathChange]);

    const onPathChange = React.useCallback(
        (path: string) => {
            pathRef.current = path;
            setLoading(true);
            handlePathChange(path);
        },
        [handlePathChange],
    );

    const errorActions = React.useMemo(() => {
        return {
            [FetchImageErrorCodes.BlueprintNotFound]: async () => {
                const path = pathRef.current;
                if (path) {
                    await wrapApiPromiseByToaster(createBlueprint(getBlueprintIdFromPath(path)), {
                        toasterName: 'CreateBlueprintInBlueprintInput',
                        skipSuccessToast: true,
                        errorTitle: 'Failed to create blueprint',
                        errorContent: (error) => {
                            const axiosError = error as AxiosError<{errors: string[]}>;
                            return `[code ${axiosError.response?.status}] ${axiosError.response?.data?.errors ? axiosError.response?.data?.errors.join(', ') : axiosError.message}`;
                        },
                    })
                        .then(() => {
                            window.open(genNavigationUrl({cluster: YT.cluster, path}));
                        })
                        .then(() => {
                            setLoading(true);
                            handlePathChange(path);
                        });
                }
            },
        };
    }, []);

    const onRefresh = React.useCallback(() => {
        const path = pathRef.current;

        if (path) {
            setLoading(true);
            handlePathChange(path);
        }
    }, []);

    return {
        image: value ?? '',
        error: error || props.error,
        errorActions,
        loading,
        onPathChange,
        defaultPath,
        onRefresh,
    };
};

export const BlueprintInputContainer: React.FC<BlueprintInputContainerProps> = (
    props: BlueprintInputContainerProps,
) => {
    return <BlueprintInput {...useBlueprintInput(props)} />;
};
