import React, {Component} from 'react';
import find_ from 'lodash/find';
import findIndex_ from 'lodash/findIndex';
import {TextInput, TextInputProps} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';

import LabelsGroup from '@ytsaurus-ui-platform/src/ui/components/LabelsGroup/LabelsGroup';

import './TractoMultiTextControl.scss';

const b = block('tracto-multi-text-control');

export type TractoMultiTextControlProps = Partial<
    Omit<
        TextInputProps,
        'value' | 'onBlur' | 'onFocus' | 'onUpdate' | 'onChange' | 'onKeyDown' | 'error'
    >
> & {
    value: Array<{name: string; isDefault?: boolean}>;
    onChange: (value: TractoMultiTextControlProps['value']) => void;
    error?: string | undefined;
};

type ItemType = TractoMultiTextControlProps['value'][0];

interface State {
    currentLabel: string;
}

export class TractoMultiTextControl extends Component<TractoMultiTextControlProps, State> {
    static ENTER = 13;

    static hasErrorRenderer = true;

    static getDefaultValue() {
        return [];
    }

    static isEmpty(value: TractoMultiTextControlProps['value']) {
        return !value.length;
    }

    static remove(theItem: ItemType, items: Array<ItemType>) {
        const index = findIndex_(items, (anItem) => theItem.name === anItem.name);
        if (index > -1) {
            const copy = [...items];
            copy.splice(index, 1);
            return copy;
        }

        return items;
    }

    state: State = {currentLabel: ''};

    updateLabel = (text: string) => {
        this.setState({currentLabel: text});
    };

    confirmText = () => {
        const {currentLabel} = this.state;

        if (currentLabel?.length > 0) {
            const {onChange, value: labels} = this.props;

            if (!find_(labels, (label) => label.name === currentLabel)) {
                onChange(labels.concat({name: currentLabel}));
            }
            this.setState({currentLabel: ''});
        }
    };

    handleAdd = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode === TractoMultiTextControl.ENTER) {
            event?.preventDefault();
            this.confirmText();
        }
    };

    handleClear = () => {
        this.props.onChange(TractoMultiTextControl.getDefaultValue());
    };

    handleRemove = (item: ItemType) => {
        const {onChange, value: items} = this.props;
        onChange(TractoMultiTextControl.remove(item, items));
    };

    render() {
        const {value: labels, onChange: _onChange, disabled, ...rest} = this.props;

        return (
            <div className={b()}>
                <TextInput
                    className={b('input')}
                    size="s"
                    view="normal"
                    autoComplete={false}
                    hasClear
                    disabled={disabled}
                    {...rest}
                    onUpdate={this.updateLabel}
                    onKeyDown={this.handleAdd}
                    {...{onBlur: this.confirmText}}
                    value={this.state.currentLabel}
                />
                <LabelsGroup
                    clas
                    items={labels}
                    visibleCount={4}
                    disabled={disabled}
                    onRemoveAll={this.handleClear}
                    onRemove={this.handleRemove}
                />
            </div>
        );
    }
}
